import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Container } from './video.css';

class Video extends Component {
  state = {
    url: `'https://www.youtube.com/watch?v=${this.props.videoID}'`,
    ratio: `${this.props.aspectratio}`,
    played: 0,
    loop: true,
    volume: `${this.props.volume}`,
  };

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.volume !== newProps.volume) {
      this.setState(newProps);
    }
  }

  handleProgress = state => {
    this.setState(state);
  };

  render() {
    const { url, ratio, played, loop, volume } = this.state;

    return (
      <Container ratio={ratio}>
        <ReactPlayer
          className="react-player"
          url={url}
          loop={loop}
          volume={volume}
          width="100%"
          height="100%"
          onProgress={this.handleProgress}
          playing
        />
        <progress max={1} value={played} />
      </Container>
    );
  }
}

Video.propTypes = {
  videoID: PropTypes.string.isRequired,
  aspectratio: PropTypes.number.isRequired,
  volume: PropTypes.number.isRequired,
};

export default Video;

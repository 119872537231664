import styled from 'styled-components';
import { color } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  margin-right: 40px;
  float: left;
  height: calc(100vh - 310px);
  width: calc(100vh*${props => props.ratio} - 310px);
  pointer-events: none;

  progress {
    background-color: ${color.white};
    border: 0;
    width: 100%;
    height: 20px;
  }
  progress::-webkit-progress-bar {
    background-color: ${color.white};
  }
  progress::-webkit-progress-value {
    background-color: ${color.black};
  }
  progress::-moz-progress-bar {
    background-color: ${color.black};
  }

  ${MEDIA.PHONE`
    width: calc(100vw - 40px);
    height: calc(100vw/${props => props.ratio} - 40px);
    margin: 0 0 40px 0;
  `};
`;

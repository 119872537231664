import styled from 'styled-components';
import { color, transition } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Frame = styled.div`
  margin: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Title = styled.div`
  margin: 30px 70px;
  height: 120px;
  width: calc(100% - 140px);
  position: relative;

  h1 {
    margin-left: 20px;
    font-size: 120px;
    line-height: 120px;
    font-weight: 800;
    text-transform: uppercase;
    color: ${color.black};
  }

  h2 {
    font-size: 18px;
    line-height: 20px;
    display: flex;
    transform: rotate(-180deg);
    writing-mode: vertical-lr;
    justify-content: center;
    width: 20px;
    height: 120px;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${MEDIA.PHONE`
  margin: 40px 0;
    width: 100%;
    height: auto;

    h1 {
      font-size: 50px;
      line-height: 50px;
      margin: 0;
      text-align: center;
    }
    h2 {
      position: relative;
      transform: rotate(0deg);
      writing-mode: horizontal-tb;
      width: 100%;
      height: auto;
    }
  `};
`;

export const ScrollWrapper = styled.div`
  margin: 0px;
  height: calc(100vh - 110px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ContentWrapper = styled.div`
  float: left;
  height: calc(100vh - 290px);
  padding-top: 180px;
  overflow: hidden;
  display: flex;
  position: relative;

  ${MEDIA.PHONE`
    height: auto;
    padding: 0;
    display: block;
    overflow: auto;
  `};
`;

export const Prev = styled.div`
  margin-right: 200px;
  height: calc(100vh - 390px);

  a {
    text-align: right;
    height: 100%;
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    .middle {
      width: 100%;

      p {
        font-size: 18px;
        line-height: 20px;
        color: ${color.black};
        margin-bottom: 20px;
      }
      h3 {
        position: relative;
        font-size: 50px;
        line-height: 60px;
        font-weight: 800;
        text-transform: uppercase;
        color: ${color.black};

        &:after {
          content: '';
          display: block;
          right: -50px;
          z-index: 1;
          position: absolute;
          width: calc(100% + 50px);
          height: 4px;
          top: calc(50% - 2px);
          background: ${color.gray};
          transition: transform 0.15s ${transition.move};
          transform-origin: 100% 0;
          transform: scaleX(0);
          z-index: -9;
        }
      }
      h4 {
        font-size: 18px;
        line-height: 20px;
        color: ${color.black};
      }
    }

    &:hover {
      h3 {
        &::after {
          transform: none;
          transition: transform 0.25s ${transition.move};
        }
      }
    }
  }
`;

export const Next = styled.div`
  margin-left: 200px;
  height: calc(100vh - 390px);

  a {
    text-align: left;
    height: 100%;
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    .middle {
      width: 100%;

      p {
        font-size: 18px;
        line-height: 20px;
        color: ${color.black};
        margin-bottom: 20px;
      }
      h3 {
        position: relative;
        font-size: 50px;
        line-height: 60px;
        font-weight: 800;
        text-transform: uppercase;
        color: ${color.black};

        &:after {
          content: '';
          display: block;
          left: -50px;
          z-index: 1;
          position: absolute;
          width: calc(100% + 50px);
          height: 4px;
          top: calc(50% - 2px);
          background: ${color.gray};
          transition: transform 0.15s ${transition.move};
          transform-origin: 0% 0%;
          transform: scaleX(0);
          z-index: -9;
        }
      }
      h4 {
        font-size: 18px;
        line-height: 20px;
        color: ${color.black};
      }
    }

    &:hover {
      h3 {
        &::after {
          transform: none;
          transition: transform 0.25s ${transition.move};
        }
      }
    }
  }
`;

export const MobilePager = styled.div`
  float: left;
  width: 100%;
  margin: 20px 0;
  position: relative;

  &:after {
    content: '';
    display: block;
    left: 50%;
    z-index: 1;
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0px;
    background: ${color.gray};
    transform: rotate(10deg);
  }
`;

export const MobilePrev = styled.div`
  float: left;
  width: calc(50% - 10px);
  padding: 20px 0 30px;

  a {
    height: 100%;
    width: 100%;
    display: block;
    color: ${color.black};

    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    h3 {
      position: relative;
      font-size: 18px;
      line-height: 24px;
      font-weight: 800;
      text-transform: uppercase;
    }
    h4 {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const MobileNext = styled.div`
  float: right;
  width: calc(50% - 10px);
  text-align: right;
  padding: 20px 0 30px;

  a {
    height: 100%;
    width: 100%;
    display: block;
    color: ${color.black};

    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    h3 {
      position: relative;
      font-size: 18px;
      line-height: 24px;
      font-weight: 800;
      text-transform: uppercase;
    }
    h4 {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

//Modules
import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import ImagesLoaded from 'react-images-loaded';
import ReactIScroll from 'react-iscroll';
const iScroll = typeof window != 'undefined' ? require('iscroll/build/iscroll-probe') : {};
import PropTypes from 'prop-types';

//Components
import Page from 'components/page';
import Video from './video';
import Stills from './stills';
import IMDb from './imdb';
import { Desktop, Mobile } from 'components/media';

//Styled
import {
  Frame,
  Title,
  ScrollWrapper,
  ContentWrapper,
  Prev,
  Next,
  MobilePager,
  MobilePrev,
  MobileNext,
} from './filmTemplate.css';


//Component
const FilmTemplate = ({ data }) => {
  // Init
  const film = data.cms.film.edges[0];
  const prev = data.cms.prev.edges[0];
  const next = data.cms.next.edges[0];
  const [volume, setVolume] = useState(1);
  let loaded = false;

  // Reference
  let refFrame = React.createRef();
  let refScroll = React.createRef();
  let refVideo = React.createRef();

  // Functions
  const imagesLoaded = () => {
    refScroll.current.refresh();
    loaded = true;
  };
  const onScroll = iScrollInstance => {
    var curScroll = iScrollInstance.x;
    var maxScroll = iScrollInstance.maxScrollX + 730;
    var volumeStart = refVideo.current.clientWidth * -1 - 530;
    var volumeEnd = volumeStart - 300;

    // Move Frame
    if (curScroll >= -730) {
      refFrame.current.style.transform = 'translateX(' + (curScroll + 730) + 'px)';
    } else if (curScroll <= maxScroll) {
      refFrame.current.style.transform = 'translateX(' + (maxScroll - curScroll) * -1 + 'px)';
    }

    // Set Volume
    if (curScroll >= volumeStart) {
      setVolume(1);
    } else if (curScroll <= volumeStart && curScroll >= volumeEnd) {
      setVolume(Math.round((1 - (curScroll - volumeStart) / -300) * 10) / 10);
    } else {
      setVolume(0);
    }
  };
  const navHover = i => {
    if (loaded) {
      if (i == 0) {
        refScroll.current._iScrollInstance.scrollTo(
          0,
          0,
          400,
          iScroll.utils.ease.quadratic
        );
        refFrame.current.style.transform = 'translateX(730px)';
      } else {
        refScroll.current._iScrollInstance.scrollTo(
          refScroll.current._iScrollInstance.maxScrollX,
          0,
          400,
          iScroll.utils.ease.quadratic
        );
        refFrame.current.style.transform = 'translateX(-730px)';
      }
    }
  };

  return (
    <Page>

      <Desktop>
        <Frame ref={refFrame}>
          <Title>
            <h1>{film.node.title}</h1>
            <h2>{film.node.Custom.year}</h2>
          </Title>
          <IMDb link={film.node.Custom.imdb} />
        </Frame>
        <ScrollWrapper>
          <ReactIScroll
            iScroll={iScroll}
            ref={refScroll}
            options={{
              mouseWheel: true,
              scrollbars: false,
              scrollX: true,
              scrollY: false,
              startX: -730,
              probeType: 2,
            }}
            onScroll={onScroll}
          >
            <ContentWrapper>
              <Prev onMouseEnter={() => navHover(0)}>
                <Link to={`/${prev.node.uri}`}>
                  <div className="middle">
                    <p>Previous</p>
                    <h3>{prev.node.title}</h3>
                    <h4>{prev.node.Custom.year}</h4>
                  </div>
                </Link>
              </Prev>

              <div ref={refVideo}>
                <Video
                  videoID={film.node.Custom.trailerYoutubeId}
                  aspectratio={film.node.Custom.aspectratio}
                  volume={volume}
                />
              </div>

              <ImagesLoaded done={imagesLoaded}>
                <Stills stills={film.node.content} />
              </ImagesLoaded>

              <Next onMouseEnter={() => navHover(1)}>
                <Link to={`/${next.node.uri}`}>
                  <div className="middle">
                    <p>Next</p>
                    <h3>{next.node.title}</h3>
                    <h4>{next.node.Custom.year}</h4>
                  </div>
                </Link>
              </Next>
            </ContentWrapper>
          </ReactIScroll>
        </ScrollWrapper>
      </Desktop>

      <Mobile>
        <Title>
          <h1>{film.node.title}</h1>
          <h2>{film.node.Custom.year}</h2>
        </Title>
        <ContentWrapper>
          <Video
            videoID={film.node.Custom.trailerYoutubeId}
            aspectratio={film.node.Custom.aspectratio}
            volume={volume}
          />
          <Stills stills={film.node.content} />
        </ContentWrapper>
        <MobilePager>
          <MobilePrev>
            <Link to={`/${prev.node.uri}`}>
              <div className="middle">
                <p>Previous</p>
                <h3>{prev.node.title}</h3>
                <h4>{prev.node.Custom.year}</h4>
              </div>
            </Link>
          </MobilePrev>
          <MobileNext>
            <Link to={`/${next.node.uri}`}>
              <div className="middle">
                <p>Next</p>
                <h3>{next.node.title}</h3>
                <h4>{next.node.Custom.year}</h4>
              </div>
            </Link>
          </MobileNext>
        </MobilePager>
      </Mobile>

    </Page>
  );
};

FilmTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($film: String!, $prev: String!, $next: String!) {
    cms {
      film: posts(where: { title: $film }) {
        edges {
          node {
            uri
            title
            Custom {
              year
              trailerYoutubeId
              aspectratio
              imdb
            }
            content
          }
        }
      }
      prev: posts(where: { title: $prev }) {
        edges {
          node {
            uri
            title
            Custom {
              year
            }
          }
        }
      }
      next: posts(where: { title: $next }) {
        edges {
          node {
            uri
            title
            Custom {
              year
            }
          }
        }
      }
    }
  }
`;

export default FilmTemplate;

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './imdb.css';

const IMDb = ({ link }) => {
  return (
    <Container>
      <a
        href={'https://www.imdb.com/title/' + link}
        target="_blank"
        rel="noopener noreferrer"
      >
        IMDb
      </a>
    </Container>
  );
};

IMDb.propTypes = {
  link: PropTypes.string.isRequired,
};

export default IMDb;

import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  ul {
    display: flex;

    li {
      margin-right: 40px;

      &:last-child {
        margin-right: 0px;
      }

      img {
        height: calc(100vh - 390px);
      }
    }
  }

  ${MEDIA.PHONE`
    ul {
      display: block;

      li {
        margin: 0 0 20px 0;
        position: relative;

        img {
          width: calc(100vw - 40px);
          height: auto;
        }
      }
    }
  `};
`;

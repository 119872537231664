import styled from 'styled-components';
import { color } from 'constants/theme';

export const Container = styled.div`
  margin: 30px 70px 40px;
  height: 40px;
  width: calc(100% - 140px);
  position: absolute;
  bottom: 0;

  a {
    font-size: 20px;
    line-height: 40px;
    font-weight: 400;
    color: ${color.black};
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './stills.css';

const Stills = ({ stills }) => {
  return (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: stills }} />
    </Container>
  );
};

Stills.propTypes = {
  stills: PropTypes.node.isRequired,
};

export default Stills;
